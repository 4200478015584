@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,400;1,600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/****** Basic ******/
:root {
  --indigo: #3423a6;
  --indigo-lighter: #6c62b4;
  --primary-red: #db162f;
  --gray: #b5b5b5;
  --blue: #010e1e;
}

body {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  @apply bg-blue text-white;
}

:focus-visible {
  outline: white solid 2px;
  border: none;
  box-shadow: none;
}

/* WebKit/Blink Browsers */
::selection {
  background: var(--indigo);
}

/* Gecko Browsers */
::-moz-selection {
  background: var(--indigo);
}

.container-fluid {
  /* Need to write this myself because tailwind default container has to much padding left/right */
  @apply mx-auto px-4 sm:px-8 md:px-10 lg:px-20;
}

.stretched-link::after {
  content: "";
  @apply absolute top-0 right-0 bottom-0 left-0 z-10;
}

/****** React Horizontal Scrolling Menu: Removes scrollbar ******/
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/****** Games overview cards: No tailwind because, transition with css looks smoother  *******/
.card-hover-border {
  transition: all 0.1s ease-in;
  border-bottom: 5px solid transparent;
}
.card-hover-border:hover {
  transform: translateY(-2px);
  border: none;
  border-bottom: 5px solid #db162f;
}

/****** Loading animation  *******/
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--indigo);
  color: var(--indigo);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: var(--indigo-lighter);
  }
  50%,
  100% {
    background-color: var(--indigo);
  }
}

.google-revocation-link-placeholder {
  display: none;
}

.inb {
  display: block;
  width: 100%;
  height: 90px;
}
@media (min-width: 1024px) {
  .inb {
    width: 728px;
  }
}
